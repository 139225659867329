import { create } from 'zustand'

interface ProcessStore {
  openProcessPopup: boolean
  setOpenProcessPopup: (open: boolean) => void
  processName: string
  setProcessName: (name: string) => void
  currentPage: number
  setCurrentPage: (page: number) => void
}

const useProcessStore = create<ProcessStore>((set) => ({
  openProcessPopup: false,
  setOpenProcessPopup: (open) => {
    set({ openProcessPopup: open })
  },
  processName: 'D-InSAR',
  setProcessName: (name) => {
    set({ processName: name })
  },
  currentPage: 1,
  setCurrentPage: (page) => {
    set({ currentPage: page })
  },
}))

export default useProcessStore
