import { Button } from '@repo/ui/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@repo/ui/components/ui/table'
import { ReceiptDollar } from '@repo/ui/icons'
import { IconMapSearch } from '@tabler/icons-react'
import { ChevronRight } from 'lucide-react'
import React from 'react'

import { ToastType } from '@/common/components/Toast/Toast'
import { createToast } from '@/utils/createToast'

type ProcessPopupSecondPhaseProps = {
  roiArea: number
  roiCost: number
  setCurrentPage: (value: number) => void
  isProcessROIEnabled: boolean
  setProcessROIEnable: (value: boolean) => void
}

const currentProcessName = 'D-InSAR'

const ProcessPopupFirstPhase = ({
  roiArea,
  roiCost,
  setCurrentPage,
  // isProcessROIEnabled,
  // setProcessROIEnable,
}: ProcessPopupSecondPhaseProps) => {
  const canProceed = roiArea > 0 && roiCost > 0

  const handleNext = () => {
    // if (!canProceed) {
    //   createToast(ToastType.ERROR, 'Please draw your process area!')
    //   return
    // }
    setCurrentPage(1)
  }

  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="w-[500px]">
        {/* Header */}
        <div className="mb-6 flex items-center gap-2 font-bold text-lg">
          <ReceiptDollar />
          <span className="font-semibold">Process Information Table</span>
          <span className="text-sm font-medium text-primary-500">
            ({currentProcessName})
          </span>
        </div>

        {/* Table */}
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/2">Field</TableHead>
              <TableHead className="w-1/2 text-center">Information</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">Overlapped Area</TableCell>
              <TableCell className="text-center">
                {roiArea.toFixed(4)}km&sup2;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium">Field Cost</TableCell>
              <TableCell className="text-center">
                ${roiCost.toFixed(4)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/* Draw Process Area Button */}
        {/* <div className="mt-6">
          <Button
            onClick={() => {
              setProcessROIEnable(!isProcessROIEnabled)
              createToast(ToastType.SEARCH, 'Draw your process area!')
            }}
            className="w-full flex gap-2"
            disabled={isProcessROIEnabled}
          >
            <IconMapSearch size={20} />
            Draw Process Area
          </Button>
        </div> */}

        {/* Next Button */}
        <Button
          variant={canProceed ? 'default' : 'secondary'}
          className="absolute right-4 top-1/2 -translate-y-1/2 rounded-md p-4"
          onClick={handleNext}
        >
          <ChevronRight className="h-6 w-6" />
        </Button>
      </div>
    </div>
  )
}

export default ProcessPopupFirstPhase
